import * as THREE from 'three'

import Waves from './Waves.js';

import { gsap } from "gsap/dist/gsap";

const scene = new THREE.Scene();

const renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});

renderer.setClearColor(0x101010, 0);
renderer.setPixelRatio(window.devicePixelRatio);

export const waves = new Waves(new THREE.Color("#ededed"));

var canvas;
var camera;

export function animate(){
    requestAnimationFrame(animate);
    waves.render();
    renderer.render(scene, camera);
}

function onWindowResize() {
    let h = canvas.offsetHeight; 
    let w = canvas.offsetWidth;
    camera.aspect = w / h
    camera.updateProjectionMatrix();
    renderer.setSize(w,h);
}

export function init(canvasElement) {
    canvas = canvasElement;

    // Create camera
    camera = new THREE.PerspectiveCamera(75, canvas.offsetWidth / canvas.offsetHeight, 0.01, 1000);
    camera.position.z = 0.5;
    camera.position.y = 0.15;

    renderer.setSize(canvas.offsetWidth, canvas.offsetHeight);
    canvas.appendChild(renderer.domElement);

    scene.add(waves);
    renderer.render(scene, camera);

    animate();

    window.addEventListener('resize', onWindowResize);

}


export function changeWavesColor(color) {
    gsap.to(waves, {
        color: color,
    });
}

const waveAnimationDuration = 2.5;
var animatingWave = false;
//var noiseAmpVal = 0.00;
var noiseAmpVal = 0.10;
var noiseFreqVal = 1.5;

export function triggerWave(reverse = false) {
    let gaussianWavePos = waves.material.uniforms.gaussianWavePos;

    let noiseAmp = waves.material.uniforms.noiseAmp;

    let dir = reverse ? -1 : 1;

    if (!animatingWave) {
        animatingWave = true;
        let tl = gsap.timeline();
        // Reduce wave noise amplitude
        tl.to(noiseAmp, {value: 0.025, duration: .75});

        // Fade out title
        //tl.to('.header-title', {opacity: 0}, "0");

        // Animate big wave
        tl.fromTo(gaussianWavePos, 
            {value: dir * 2.25}, 
            {
                value: dir * -2.25, 
                duration: waveAnimationDuration,
            },
            "<");

        // Scroll to introduction section
        //tl.to(document.documentElement,
        //    {
        //        duration: 1,
        //        scrollTo: '#introduction-section-container',
        //        ease: "power3.in",
        //    },
        //    ">-=1");

        // Go back to default wave noise amplitudes
        tl.to(noiseAmp, {
            value: noiseAmpVal,
            duration: 1.5,
            ease: "power1.in",
            onComplete: () => {
                animatingWave = false;
            }
        });
        // Fade in title
        //tl.to('.header-title', {opacity: 1});
        return true;
    }

    return false;
}

export function triggerRipple() {
    let gaussianWavePos = waves.material.uniforms.gaussianWavePos;
    let gaussianWaveAmp = waves.material.uniforms.gaussianWaveAmp;

    if (!animatingWave) {

        let gaussianWaveAmpOld = gaussianWaveAmp.value;
        gaussianWaveAmp.value = 0.075;

        animatingWave = true;
        // set ripple effect on
        waves.material.uniforms.gaussianRipple.value = true;
        let tl = gsap.timeline();

        // Reduce wave noise amplitude
        //tl.to(noiseAmp, {value: 0.025, duration: 1});

        // Animate Ripple
        tl.fromTo(gaussianWavePos, 
            {value: -0.6}, 
            {
                value: 3, 
                duration: waveAnimationDuration,
                onComplete: () => {
                    animatingWave = false;
                    gaussianWaveAmp.value = gaussianWaveAmpOld;
                    waves.material.uniforms.gaussianRipple.value = false;
                }
            },
            "<");

        // Go back to default wave noise amplitudes
        //tl.to(noiseAmp, {
        //    value: noiseAmpVal,
        //    duration: 2,
        //    onComplete: () => {
        //        animatingWave = false;
        //        Scene.waves.material.uniforms.gaussianRipple.value = false;
        //    }
        //});

        return true;
    }

    return false;
}

export function openNav() {
    
}
