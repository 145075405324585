// Third-party Libs
import VanillaTilt from 'vanilla-tilt';

// Custom Modules
import * as Scene from './scenes/scene.js';

import * as IntroSection from './introSection.js';

import * as PortfolioScene from './portfolioSection.js';

import * as MenuScene from './scenes/menuScene.js';

import ExperienceSection from './experienceSection.js';

import Cursor from './cursor.js';

import NavigationManager from './NavigationManager.js';

import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import { TextPlugin } from "gsap/dist/TextPlugin";
gsap.registerPlugin(ScrollTrigger,ScrollToPlugin,TextPlugin);

import $ from 'jquery';

window.$ = $;
window.jQuery = jQuery;






const wavesContainer = document.getElementById('waves-container');
export const navManager = new NavigationManager();

//var noiseAmpVal = 0.00;
var noiseAmpVal = 0.10;
var noiseFreqVal = 1.5;



$(document).ready(function (){
    setViewHeight();
    window.addEventListener('resize', setViewHeight);
    window.addEventListener('touchmove', setViewHeight);
    window.addEventListener('resize', navManager.handleScreenChange.bind(navManager));
    window.addEventListener('change', navManager.handleScreenChange.bind(navManager));

    /* Initialize morphing sphere menu button */
    MenuScene.init();

    /* Initialize THREE.js scene */
    Scene.init(wavesContainer);
    Scene.waves.changeNoiseAmp(noiseAmpVal);
    Scene.waves.changeNoiseFreq(noiseFreqVal);

    // Fade out overlay
    let loaderOverlay = $("#loader-overlay");
    let headerContainer = $("#header-title-container");
    let headerSubtitle = $("#header-subtitle");
    loaderOverlay.addClass('loaded');


    // Fade in header
    gsap.fromTo('.header-title', {opacity: 0}, {opacity: 1, duration: 0.7, delay: 0.5, ease: 'power2.in', onStart: () => {
        loaderOverlay.addClass('hidden');
        headerContainer.removeClass('hidden');
        headerContainer.addClass('active');
    }});

    gsap.fromTo('#header-subtitle', {opacity: 0}, {opacity: 1, duration: 0.7, delay: 0.5, ease: 'power2.in'});



    gsap.fromTo('#socials a', {opacity: 0}, {opacity: 1, duration: 0.9, delay: 0.5, ease: 'power2.in', stagger: 0.1});

    $("#nav-menu-scene").on("click", () => triggerRipple());

});

export function navigateToThesis() {
    PortfolioScene.changePortfolioItem(3);
    navManager.navigateTo("#portfolio-section-container");
}





var blackSchemeOn = true;

function setViewHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
}

export function changeColorScheme() {
    blackSchemeOn = !blackSchemeOn
    if (blackSchemeOn) {
        // dark scheme
        document.documentElement.style.setProperty('--primary-dark', '16, 16, 16');
        document.documentElement.style.setProperty('--primary-light', '#fff');
        document.documentElement.style.setProperty('--primary-light-grey', '237, 237, 237');
        Scene.changeWavesColor('#ededed');
    }else {
        // light scheme
        document.documentElement.style.setProperty('--primary-dark', '237, 237, 237');
        document.documentElement.style.setProperty('--primary-light', '#000');
        document.documentElement.style.setProperty('--primary-light-grey', '16, 16, 16');
        Scene.changeWavesColor('#101010');
    }

}

/* Initialize GSAP and setup horizontal scrolling */
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(TextPlugin);

export function stopWaves() {
    let noiseAmp = Scene.waves.material.uniforms.noiseAmp;
    gsap.to(noiseAmp, {value: 0, duration: 2});
}

export function scrollTo(elementId) {
    // Determine scroll direction
    let elem = document.getElementById(elementId);

    // Determine elements index
    let parentChildren = Array.prototype.slice.call(elem.parentElement.children);
    let index = parentChildren.indexOf(elem);

    // Determine element position
    let isBackward = elem.getBoundingClientRect().x < 0 ? true : false;

    // Trigger wave animation and store whether it will play
    let waveAnim = triggerWave(isBackward);
    // Only delay scroll if animation will play
    let delay = waveAnim ? .66 : 0;

    // Scroll to correct section
    gsap.to(".scroll-container", {
        transform: `translateX(${-100 * index}%)`,
        delay: delay,
    });
}

export function goToIntroSection() {

        // Get active elem and fade out

        triggerRipple();

        // Fade out title
        gsap.to(".active", {
            opacity: 0,
            onComplete: () => {
                $(".active").toggleClass('hidden');
                $(".active").toggleClass('active');
                IntroSection.fadeIn();
            }
        });
}

export function goToSection(sectionId) {

    let targetElem = $(sectionId);

    if (!targetElem) { return; } 

    triggerRipple();
    // Remove active from current element
    let activeElem = $(".active")
    activeElem.removeClass('active');


    let delay = .66;

    let tl = gsap.timeline();
    tl.to(activeElem, {
        opacity: 0.0,
        ease: "power2.out",
        duration: .75,
        onComplete: () => {
            activeElem.addClass('hidden');
        },
    });

    tl.fromTo(targetElem, {opacity: 0.0}, {
        opacity: 1.0,
        ease: "power2.in",
        delay: delay,
        duration: .5,
        onStart: () => {
            targetElem.removeClass('hidden');
            targetElem.addClass('active').trigger('becomesactive');
        },
    }, "<");

}


export function hideWave() {
    triggerRipple();
    gsap.to("#waves", {
        opacity: 0.0,
        delay: 1,
        duration: 0.35,
        ease: "power2.out",
    });

    gsap.to("#scroll-section", {
        opacity: 0.0,
        delay: 1,
        duration: 0.35,
        transform: "translateY(8px)",
        ease: "power2.out",
    });

}

export function toggleNav() {
    MenuScene.toggleNav();
}
